@use 'colors' as *;
@use 'linda' as *;

.mat-icon.zef-icons-full {
  overflow: visible;

  user-select: none;
  text-decoration: none !important;

  &,
  &.zef-icon-medium {
    @include icon-size(24);
  }

  &.zef-icon-micro {
    @include icon-size(12);
  }

  &.zef-icon-small {
    @include icon-size(16);
  }

  &.zef-icon-large {
    @include icon-size(32);
  }

  &.zef-icon-larger {
    @include icon-size(48);
  }

  &.zef-icon-largest {
    @include icon-size(64);
  }

  &.zef-icon-huge {
    @include icon-size(88);
  }

  &.zef-icon-button-width {
    @include icon-size(24);

    width: 32px;

    text-align: center;
  }
}

.mat-chip .mat-icon {
  @include icon-size(18);
  margin: 0 0 0 8px;

  vertical-align: middle;
}

$palettes: sms, link, emails, responses, contacts, promo, embed, popup, ink, stealth, accent, primary, secondary, alert,
  warning, success, space, scoring, outcome, logic, trigger, smart;

@each $palette in $palettes {
  .mat-icon.mat-#{$palette} {
    color: zef-color(map-get($zef-palettes, $palette), if($palette == warning, dark, default));
  }
}

.mat-menu-item:hover .mat-icon {
  color: inherit;
}

.mat-icon.mat-ink {
  color: zef-color($ink);
}

.mat-icon.mat-ink-light {
  color: zef-color($ink, light);
}

.mat-icon.mat-ink-lighter {
  color: zef-color($ink, lighter);
}

.mat-icon.mat-ink-lightest {
  color: zef-color($ink, lightest);
}

.mat-icon.mat-space-darkest {
  color: zef-color($space, darkest);
}

.mat-icon-button.mat-ink-light {
  background-color: zef-color($ink, light);

  .mat-icon {
    color: zef-contrast($ink, light);
  }
}

.mat-icon {
  display: inline-flex;
  flex: none;
  align-items: center;
  justify-content: center;
}

@each $palette in map_keys($zef-color-social) {
  @if $palette != contrast {
    .mat-icon.mat-#{$palette} {
      color: map-get($zef-color-social, $palette);

      &.zef-color-keep {
        color: map-get($zef-color-social, $palette) !important;
      }
    }

    .mat-icon-button.mat-#{$palette} {
      background-color: map-get($zef-color-social, $palette);

      .mat-icon {
        color: map-get(map-get($zef-color-social, contrast), $palette);
      }
    }
  }
}
