@use 'colors' as *;

.mat-checkbox {
  &.ng-invalid {
    .mat-checkbox-frame {
      border-color: zef-color($alert);
    }
  }

  &-layout {
    align-items: flex-start;

    white-space: normal;
  }

  &-inner-container {
    width: 24px;
    height: 24px;
    margin: 0 8px 0 0;
  }

  &-ripple {
    display: none;
  }

  &-label {
    position: relative;
    top: 1px;

    font-size: 14px;
    line-height: 22px;
  }

  &-background {
    border-radius: 5px;

    > svg {
      display: none;
    }

    &::after {
      content: '\e958';

      opacity: 0;
      width: 24px;
      height: 24px;

      font-size: 24px;
      font-family: 'zef-icons-full' !important;
      line-height: 24px;
      color: white;

      transition: opacity 100ms ease-in-out 100ms;
    }
  }

  &-frame {
    border-color: zef-color($ink, lighter);
    border-radius: 5px;

    background-color: zef-color($secondary);
  }

  &.mat-checkbox-checked .mat-checkbox-background::after {
    opacity: 1;
  }

  &-disabled {
    opacity: 0.3;
  }
}

.zef-checkbox-round.mat-checkbox {
  .mat-checkbox-frame,
  .mat-checkbox-background {
    border-radius: 50%;
  }

  &:not(.mat-checkbox-checked) {
    .mat-checkbox-background {
      background-color: zef-color($ink, lightest);
    }

    .mat-checkbox-background::after {
      opacity: 1;
    }
  }
}
