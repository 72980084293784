@use 'colors' as *;

[class*='zef-text-'] {
  > a:not(.no-margin) {
    margin-right: 4px;
    margin-left: 4px;
  }
}

%zef-text-link {
  cursor: pointer;
  text-decoration: none;
  color: zef-color($primary);

  &:active,
  &:hover {
    text-decoration: underline;

    & > * {
      color: inherit;
    }
  }
}

.zef-text-pointer {
  cursor: pointer;
}

.zef-text-link-underline {
  @extend .zef-text-pointer;
  text-decoration: underline;
}

.zef-text-link-flex {
  display: inline-flex;
  align-items: center;

  text-decoration: none;

  & > * + * {
    margin-left: 4px;
  }
}

a.zef-text-link-flex:hover {
  span {
    text-decoration: underline;
  }
}

.zef-text-link-hover-underline {
  @extend .zef-text-pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.zef-text-muted {
  color: zef-color($ink, lighter);
}

.zef-text-muted-lightest {
  color: zef-color($ink, lightest);
}

%zef-text-link-muted {
  @extend %zef-text-link;
  @extend .zef-text-muted;
  text-decoration: underline;
}

[class*='zef-text-d'] {
  margin: 0;

  font-weight: 400;
  font-family: 'Secular One', serif;
}

.zef-text-d1 {
  font-size: 64px;
  line-height: 72px;
}

.zef-text-d2 {
  font-size: 48px;
  line-height: 56px;
}

.zef-text-d3 {
  font-size: 32px;
  line-height: 40px;
}

[class*='zef-text-h'] {
  margin: 0;

  font-weight: 600 !important;
  font-family: 'Open Sans', sans-serif;
}

.zef-text-h0 {
  font-size: 40px;
  line-height: 56px;
}

.zef-text-h1 {
  font-size: 24px;
  line-height: 32px;

  &-muted {
    @extend .zef-text-h1;
    @extend .zef-text-muted-lightest;
  }
}

.zef-text-h2 {
  font-size: 18px;
  line-height: 24px;

  &-muted {
    @extend .zef-text-h2;
    @extend .zef-text-muted-lightest;
  }
}

.zef-text-h3 {
  font-size: 14px;
  line-height: 20px;

  &-muted {
    @extend .zef-text-h3;
    @extend .zef-text-muted-lightest;
  }

  &-link {
    @extend .zef-text-h3;
    @extend %zef-text-link;
  }
}

.zef-text-h4 {
  font-size: 12px;
  line-height: 18px;

  &-muted {
    @extend .zef-text-h4;
    @extend .zef-text-muted-lightest;
  }
}

[class*='zef-web-h'] {
  margin: 0 0 16px;

  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.33;
}

.zef-web-h1 {
  font-size: 25px;
}

.zef-web-h2 {
  font-size: 18px;
}

.zef-web-h3 {
  font-size: 14px;
}

[class*='zef-text-p'] {
  margin-top: 0;
  margin-bottom: 0;

  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
}

strong[class*='zef-text-p'] {
  font-weight: 600;
}

.zef-text-p0 {
  font-size: 18px;
  line-height: 28px;

  &-light {
    @extend .zef-text-p0;

    font-weight: 300;
  }
}

.zef-text-p1 {
  font-size: 14px;
  line-height: 22px;

  &-link {
    @extend .zef-text-p1;
    @extend %zef-text-link;

    &-muted {
      @extend .zef-text-p1;
      @extend %zef-text-link-muted;
    }
  }

  &-muted {
    @extend .zef-text-p1;
    color: zef-color($ink, lighter);
  }
}

.zef-text-p2 {
  font-size: 13px;
  line-height: 20px;

  &-link {
    @extend .zef-text-p2;
    @extend %zef-text-link;

    &-muted {
      @extend .zef-text-p2;
      @extend %zef-text-link-muted;
    }
  }

  &-muted {
    @extend .zef-text-p2;
    color: zef-color($ink, lighter);
  }
}

.zef-text-p3 {
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;

  &-muted {
    @extend .zef-text-p3;
    color: zef-color($ink, lighter);
  }
}

.zef-text-p3-11 {
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
}

.zef-code-p1 {
  font-size: 14px;
  font-family: 'Ubuntu Mono', monospace;
  line-height: 20px;
  color: zef-color($vibrant);
}

%zef-text-b {
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
}

.zef-text-b1 {
  @extend %zef-text-b;
  font-size: 32px;
  line-height: 32px;
}

.zef-text-b2 {
  @extend %zef-text-b;
  font-size: 18px;
  line-height: 24px;
}

[class*='zef-code-'] {
  margin: 0;

  font-weight: 400;
  font-family: 'Ubuntu Mono', monospace;
  color: zef-color($vibrant);
}

[class*='zef-web-p'] {
  margin-top: 0;
  margin-bottom: 0;

  &:not(:last-of-type) {
    margin-bottom: 24px;
  }

  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.5;
}

.zef-web-p1 {
  font-size: 14px;
}

.zef-web-p2 {
  font-size: 13px;
}

.zef-text-bold {
  font-weight: 600;
}

.zef-text-no-decoration {
  text-decoration: none;
}

.zef-text-underline {
  text-decoration: underline;
}

.zef-text-italic {
  font-style: italic;
}

.zef-text-center {
  text-align: center;
}

.zef-text-disabled {
  opacity: 0.3;

  pointer-events: none;
}

.zef-text-no-wrap {
  white-space: nowrap;
}

.zef-text-break {
  word-break: break-word;
}

.zef-text-overflow-line {
  overflow: hidden;

  white-space: nowrap;
}

.zef-text-overflow-double {
  overflow: hidden;
  max-height: 40px;
  word-break: break-word;
}

.zef-text-ellipsis {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.zef-text-align-left {
  text-align: left;
}

.zef-text-align-right {
  text-align: right;
}

.zef-text-align-center {
  text-align: center;
}

.zef-text-transparent {
  color: transparent;
}

.zef-text-with-spacing {
  * + * {
    margin-left: 4px;
  }
}
