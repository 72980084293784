@use 'material' as mat;
@use 'colors' as *;
@use 'mixins' as *;
@use 'linda' as *;

$zef-typography: mat.define-legacy-typography-config(
  $font-family: 'Open sans',
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($zef-typography);`

@include mat.all-legacy-component-typographies($zef-typography);
@include mat.legacy-core();

$zef-theme-foreground: (
  base: zef-color($ink, default),
  divider: zef-color($space, darkest),
  dividers: zef-color($space, darkest),
  disabled: zef-color($ink, lightest),
  disabled-button: rgba(255, 255, 255, 0.7),
  disabled-text: zef-color($ink, lightest),
  hint-text: zef-color($ink, lightest),
  secondary-text: zef-color($ink, lightest),
  icon: zef-color($ink, default),
  icons: zef-color($ink, default),
  text: zef-color($ink, default),
  slider-min: zef-color($space, darkest),
  slider-off: zef-color($space, darkest),
  slider-off-active: zef-color($ink, lightest),
);

$zef-theme-background: (
  status-bar: #fff,
  app-bar: #fff,
  background: zef-color($space, dark),
  hover: zef-color($space, darkest),
  card: #fff,
  dialog: #fff,
  disabled-button: rgba(255, 255, 255, 0.7),
  raised-button: #fff,
  focused-button: zef-color($space, dark),
  selected-button: zef-color($ink, lightest),
  selected-disabled-button: zef-color($space, darkest),
  disabled-button-toggle: zef-color($space, darkest),
  unselected-chip: zef-color($space, darkest),
  disabled-list-option: zef-color($space, darkest),
);

@function zef-mat-theme($primary, $accent, $warn) {
  @return mat.define-light-theme((
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
      foreground: $zef-theme-foreground,
      background: $zef-theme-background
    )));
}

$theme: zef-mat-theme($primary, $accent, $warning);

@include mat.all-legacy-component-themes($theme);

@mixin item-type($color) {
  &.active,
  &.color {
    border-color: zef-color($color);

    background-color: mix(zef-color($secondary), zef-color($color), 90%);
  }

  &.addable {
    border-color: zef-color($color);
  }

  &.mat-icon,
  .mat-icon {
    color: zef-color($color);
  }
}

.zef-visual {
  .zef-close-button {
    position: absolute;
    z-index: 10;
    top: 4px;
    right: 4px;

    &.left {
      right: auto;
      left: 4px;
    }
  }

  .mat-icon {
    &.disabled {
      color: zef-color($space, darkest);
    }

    &.mat-alert {
      color: zef-color($alert, default);
    }
  }

  .mat-title,
  .mat-headline,
  .mat-subheading-1,
  .mat-subheading-2 {
    &.mat-alert {
      color: zef-color($alert, default);
    }
  }

  .mat-error {
    font-size: 14px;
    color: zef-color($alert, default);
  }

  .mat-headline {
    overflow: hidden;
    margin: 0;

    font-size: 24px;
    font-weight: bold;
    text-overflow: ellipsis;
    color: inherit;

    span {
      padding: 0 16px;

      color: zef-color($ink, lightest);
    }
  }

  .mat-title {
    overflow: hidden;
    margin: 0;

    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    text-overflow: ellipsis;
    color: inherit;
  }

  .mat-subheading-2 {
    overflow: hidden;
    margin: 0;

    font-size: 14px;
    font-weight: bold;
    text-overflow: ellipsis;
    color: inherit;
  }

  .mat-subheading-1 {
    overflow: hidden;
    margin: 0;

    font-size: 12px;
    font-weight: bold;
    text-overflow: ellipsis;
    color: zef-color($ink, lighter);
  }

  .mat-caption {
    font-size: 12px;
    color: zef-color($ink, lightest);
  }

  // LINDA INSET STYLES

  .mat-inset-z0 {
    padding: 16px;
    border: none;
    border-radius: 10px;

    background: zef-color($space, default);

    &.mat-warn {
      color: zef-color($warning, darker);
      background: zef-color($warning, lighter);
    }

    &.mat-alert {
      color: zef-color($alert, darker);
      background: zef-color($alert, lighter);
    }

    &.mat-success {
      color: zef-color($success, dark);
      background: zef-color($success, lighter);
    }

    &.mat-primary {
      color: zef-color($primary, dark);
      background: zef-color($primary, lighter);
    }
  }

  .mat-inset-z1 {
    padding: 16px;
    border: solid 1px rgba(zef-color($ink, default), 0.1);

    background: zef-color($space, default);
    // box-shadow: inset 0 1px 2px 0 rgba(zef-color($ink, default), 0.2);

    &:not(.zef-rounded) {
      border-radius: 3px;
    }

    &.mat-warn {
      border-color: zef-color($warning, default);

      color: zef-color($warning, darker);
      background: zef-color($warning, lighter);
    }

    &.mat-alert {
      border-color: zef-color($alert, default);

      color: zef-color($alert, darker);
      background: zef-color($alert, lighter);
    }

    &.mat-success {
      border-color: zef-color($success, default);

      color: zef-color($success, darker);
      background: zef-color($success, lighter);
    }
  }

  // LINDA ELEVATION STYLES

  .mat-elevation-z1 {
    @include mat.elevation(1, zef-color($ink));
  }

  .mat-elevation-z2 {
    @include mat.elevation(2, zef-color($ink));
  }

  .mat-elevation-z4 {
    @include mat.elevation(4, zef-color($ink));
  }

  .mat-elevation-z8 {
    @include mat.elevation(8, zef-color($ink));
  }

  .mat-elevation-z16 {
    @include mat.elevation(16, zef-color($ink));
  }

  // SPINNER

  .mat-spinner {
    &.mat-accent {
      path {
        stroke: #fff;
      }
    }
  }

  // TOOLBAR

  .mat-toolbar {
    height: auto;
    min-height: 56px;

    border-bottom: 1px solid zef-color($space, darkest);

    background-color: #fff;

    .mat-toolbar-row {
      height: auto;
      min-height: 56px;
    }

    .mat-toolbar-layout {
      width: 100%;
    }

    &.mat-secondary {
      color: #fff;
      background-color: zef-color($primary);
    }

    &.mat-ink {
      color: #fff;
      background-color: zef-color($ink);
    }
  }

  // TAB GROUP / NAV BAR

  .mat-tab-group,
  .mat-tab-nav-bar {
    min-height: 0;
    border-bottom: none;

    &.hide-ink-bar {
      .mat-tab-links {
        .mat-ink-bar {
          display: none;
        }
      }
    }

    .mat-tab-links {
      height: 100%;
    }

    .mat-tab-links,
    .mat-tab-labels {
      display: flex;
      align-items: center;

      .mat-tab-label {
        flex: 1;
      }

      .mat-tab-link,
      .mat-tab-label {
        min-width: 80px;
        padding: 0 8px;
        margin: 0;

        color: zef-color($ink, default);

        &:focus {
          background-color: zef-color($space, dark);
        }

        &.active,
        &.mat-tab-label-active {
          opacity: 1;
          border: none;

          background: transparent;
        }
      }
    }

    .mat-tab-body-content {
      display: flex;
      height: 100%;
    }

    .mat-tab-body-wrapper {
      flex: 1 1 auto;
      height: 100%;
    }
  }

  // CARD

  .mat-card {
    padding: 24px;

    .mat-card-title {
      font-size: 16px;
      font-weight: bold;
    }

    .mat-card-content {
      margin: 0;
    }
  }

  // LIST & ITEM

  .mat-list,
  .mat-nav-list {
    padding: 0;

    .mat-list-item {
      position: relative;

      .mat-icon {
        width: 32px;
        height: 32px;

        line-height: 32px;
        text-align: center;

        & ~ span {
          margin: 0 0 0 8px;
        }
      }

      &,
      &.mat-list-item-with-avatar {
        .mat-list-item-content {
          height: 100%;
          min-height: 40px;

          font-size: 14px;
        }
      }
    }
  }

  .mat-nav-list {
    .mat-list-item {
      &:hover {
        background-color: zef-color($space, darkest);
      }
    }
  }

  // MENU PANEL

  .mat-menu-panel {
    min-width: 200px;
    min-height: auto;
    max-width: 100%;
    border-radius: 10px;

    .mat-menu-content {
      overflow: hidden;
      padding: 0;

      .mat-menu-item {
        &:hover:not([disabled]) {
          color: zef-color($secondary);
          background-color: zef-color($primary);

          .mat-subheading-1 {
            color: zef-color($space, dark);
          }

          .mat-icon {
            color: zef-color($secondary);
          }
        }

        &.alert {
          color: zef-color($alert);

          &:hover:not([disabled]) {
            background-color: zef-color($alert);
          }

          .mat-icon {
            color: zef-color($alert);
          }
        }

        &[disabled] {
          opacity: 0.3;
        }
      }
    }
  }

  .mat-menu-panel:not([class*='zef-']) {
    .mat-menu-item {
      height: 40px;

      font-size: 14px;
      line-height: 40px;
    }
  }
  // SLIDER

  .mat-slider-horizontal {
    .mat-slider-wrapper {
      height: 4px;

      .mat-slider-track-wrapper {
        height: 100%;
        border-radius: 4px;

        .mat-slider-track-fill {
          height: 4px;
        }

        .mat-slider-track-background {
          margin-top: 1px;
        }
      }

      .mat-slider-thumb-container {
        .mat-slider-thumb {
          transform: scale(1);
        }
      }
    }
  }

  // SELECT INPUT

  .mat-select {
    display: inline-flex;
    overflow: hidden;
    box-sizing: border-box;
    align-items: center;
    padding: 4px 8px;
    border: 1px solid zef-color($space, darkest);
    border-radius: 3px;

    background-color: #fff;

    .mat-select-trigger {
      font-size: 14px;

      .mat-select-value {
        max-width: none;
      }

      .mat-select-arrow-wrapper {
        &::after {
          content: '\e956';

          margin-left: 4px;

          font-family: 'zef-icons-full';
        }

        .mat-select-arrow {
          display: none;
        }
      }
    }

    &.mat-secondary {
      min-width: 140px;

      background-color: white;
      // background-image: linear-gradient(180deg, transparent 0%, rgba(black, 0.05) 100%);
      background-image: zef-color($gradient, emboss);

      .mat-select-trigger {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }

  // SELECT OPTION
  .mat-option {
    &.z-selected {
      color: zef-color($primary);
    }
  }

  // CHIP
  .mat-chip {
    &.small {
      display: inline-flex;
      align-items: center;
      justify-content: center;

      height: 20px;
      min-height: 20px;
      padding: 0 12px;
      border-radius: 10px;

      font-size: 10px;
      text-transform: uppercase;
    }
  }

  // SLIDE toggled

  // FORM INPUTS

  .mat-form-field-infix {
    width: auto;
  }

  // INPUT CONTAINER

  .mat-form-field {
    overflow: auto;
    box-sizing: border-box;
    width: 100%;
    padding: 0;
    border: 1px solid zef-color($space, darker);
    border-radius: 5px;

    font-size: 14px;
    background-color: #fff;

    &.zef-small {
      height: 32px;
      min-height: 32px;
      max-width: initial;
      padding: 0 !important;

      .mat-form-field-prefix + .mat-form-field-infix {
        padding: 4px !important;
      }
    }

    &:not(.mat-form-field-disabled) {
      &:hover {
        border-color: zef-color($ink, lightest);
      }

      &.mat-focused {
        border-color: zef-color($primary, default);
      }
    }

    &.mat-success {
      .mat-form-field-infix {
        color: zef-color($success, darker);
        background: zef-color($success, lighter);
      }
    }

    .mat-form-field-wrapper {
      display: flex;
      flex: 1 1 auto;
      flex-direction: inherit;
      height: 100%;
      padding: 0;
      margin: 0;

      .mat-form-field-flex {
        overflow: hidden;
        flex: 1 1 auto;
        flex-direction: inherit;
        align-items: center;
        height: 100%;
        padding-top: 0;

        .mat-form-field-infix {
          display: flex;
          box-sizing: border-box;
          flex: 1 1 auto;
          flex-direction: inherit;
          width: 100%;
          height: 100%;
          padding: 4px 12px;
          border: none;

          line-height: 1.6;

          input {
            margin: 0;

            line-height: 1.6;
          }

          textarea {
            flex: 1 1 auto;
            height: 100%;
            margin: 0;

            resize: none;
            line-height: 1.6;
          }

          .cdk-textarea-autosize {
            overflow: hidden;
          }

          .mat-chip-selected {
            position: absolute;
            top: 0;
            right: 0;

            padding: 0 12px;
            margin: 4px;
            border-radius: 10px;

            background-color: zef-color($ink);
          }

          .mat-form-field-label-wrapper {
            top: 0;

            height: 38px;
            max-height: 100%;
            padding: 0;

            line-height: 1.6;

            .mat-form-field-label {
              top: 50%;
              left: 12px;

              width: calc(100% - 20px);

              color: zef-color($ink, lightest);

              transform: translateY(-50%) perspective(100px);

              .mat-form-field-required-marker {
                color: zef-color($alert);
              }
            }
          }
        }

        .mat-form-field-prefix,
        .mat-form-field-suffix {
          box-sizing: border-box;
          height: 100%;
          padding: 4px 8px;
        }

        .mat-form-field-suffix {
          .mat-icon-button {
            width: 24px;
            height: 24px;
            border: none;

            line-height: 24px;
          }

          .mat-select {
            width: 120px;
            height: 30px;
            border-left: 1px solid zef-color($space, darkest);

            color: zef-color($ink, lightest);
            background-color: zef-color($space, darkest);

            .mat-select-trigger {
              .mat-select-underline {
                display: none;
              }
            }
          }
        }
      }

      .mat-form-field-underline {
        display: none;
      }
    }
  }

  // DRAWER CONTAINER styles

  .mat-drawer-container {
    .mat-drawer-inner-container {
      display: flex;
    }
  }

  // LINDA DIALOG CONTAINER STYLES

  .mat-dialog-container {
    position: relative;

    height: auto;
    min-width: 720px;
    min-height: 320px;
    padding: 0;
    border-radius: 10px;

    box-shadow: 0 4px 8px 0 rgba(zef-color($ink, default), 0.2);

    & > * {
      width: 100%;
      height: 100%;
    }

    .mat-icon-button {
      &[matdialogclose] {
        position: absolute;
        z-index: 10;
        top: 16px;
        right: 16px;

        width: 32px;
        height: 32px;
        border: none;
        border-radius: 5px;

        background: #fff;

        &.shadow {
          background-color: zef-color($space, darker);
        }
      }
    }

    .mat-dialog-title {
      margin: 0;

      font-weight: bold;
    }

    .mat-dialog-content {
      position: relative;

      overflow: hidden;
      max-height: none;
      padding: 24px;
      margin: 0;
      border-radius: 10px;

      .mat-form-field {
        box-sizing: border-box;
        padding: 8px;

        background: #fff;

        &.mat-form-field-type-mat-chip-list {
          min-height: 58px;
        }
      }
    }

    .mat-dialog-actions {
      min-height: auto;
      padding: 0;
      margin: 0;
    }
  }

  // Custom styles for transparent dialog
  .transparent-dialog .mat-dialog-container {
    background: transparent;
    box-shadow: none;
  }

  // TEXT AREA

  .zef-textarea-default {
    height: 64px;
    min-height: 64px;
    padding: 8px 16px;
    border: 1px solid zef-color($space, darkest);
    border-radius: 3px;

    font-size: 14px;
    background-color: zef-color($secondary);
    box-shadow: inset 0 1px 4px -2px rgba(0, 0, 0, 0.25);

    &:hover,
    &:focus {
      border-color: zef-color($primary);
    }

    &::placeholder {
      color: zef-color($ink, lightest);
    }
  }

  // RADIO BUTTON

  // CHECKBOX

  // LINDA CDK OVERLAY STYLES

  .cdk-overlay-pane {
    max-height: 80vh;

    &.zef-focus-field-pane {
      @include linda-shadow(4);
    }

    &.zef-no-min-height {
      max-height: 100%;

      .mat-dialog-container {
        min-height: 0;
      }
    }

    &.zef-toolbar {
      cdk-dialog-container {
        width: 100%;
        padding: 0;
        outline: 0;

        background: transparent;
      }
    }

    &.zef-sidenav {
      max-height: 100%;

      cdk-dialog-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 0;
        outline: 0;

        background: transparent;

        > * {
          height: 100%;
        }
      }
    }
  }

  .cdk-overlay-container {
    .zef-sidenav {
      &.left .sidenav {
        box-shadow: 8px 0 16px 0 transparentize(zef-color($ink, default), 0.8);
      }

      &.right .sidenav {
        box-shadow: -8px 0 16px 0 transparentize(zef-color($ink, default), 0.8);
      }
    }
  }

  .cdk-overlay-dark-backdrop {
    background-color: rgba(zef-color($ink), 0.5);
  }

  .zef-focus-field-backdrop {
    background-color: rgba(zef-color($ink), 0.1);
  }

  .zef-focus-box-scrim {
    background-color: transparentize(zef-color($ink), 0.9);

    + .cdk-overlay-connected-position-bounding-box {
      max-height: 800px;

      .cdk-overlay-pane {
        max-height: 100%;
      }
    }
  }

  .cdk-overlay-transparent-backdrop {
    background-color: transparent !important;

    &.zef-select {
      + .cdk-overlay-connected-position-bounding-box {
        overflow: hidden;

        .cdk-overlay-pane {
          max-width: calc(100% - 16px);
          margin: 8px;
        }
      }
    }
  }

  // GRID / GRID ITEMS

  .grid {
    .grid-placeholder {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  // LINDA PERFECT-SCROLLBAR STYLES

  $rails: x, y;

  .ps {
    @each $rail in $rails {
      &.ps--active-#{$rail} {
        .ps__rail-#{$rail} {
          opacity: 1 !important;

          background: zef-color($space, dark);

          .ps__thumb-#{$rail} {
            z-index: 10;

            opacity: 0.6;
            box-sizing: border-box;
            border: solid 4px zef-color($space, dark);
            border-radius: 8px !important;

            background: zef-color($ink, lightest);

            transition: 200ms ease-in-out opacity;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }

    &.ps--active-y {
      .ps-content {
        padding-right: 16px;
      }

      .ps__rail-y {
        width: 16px !important;

        .ps__thumb-y {
          right: 0 !important;

          width: 16px !important;
          min-height: 16px;
        }
      }
    }

    &.ps--active-x {
      .ps-content {
        padding-bottom: 16px;
      }

      .ps__rail-x {
        height: 16px !important;

        .ps__thumb-x {
          bottom: 0 !important;

          height: 16px !important;
          min-width: 16px;
        }
      }
    }
  }

  ng-scrollbar.z-scroll-thin {
    .ps {
      .ps__rail-y {
        width: 4px !important;

        .ps__thumb-y {
          width: 4px !important;
          min-height: 4px;
          border-width: 0;
          border-radius: 2px;
        }
      }
    }
  }

  // FONT / COLOR PICKER

  .font-picker,
  .color-picker {
    margin: -12px 0 0 !important;
    border: 1px solid zef-color($space, darkest) !important;

    color: zef-color($ink, light) !important;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.25) !important;

    .arrow {
      display: none !important;
    }
  }

  .font-picker {
    margin-left: -1px !important;

    .font-list {
      .font-group {
        flex-direction: row-reverse !important;

        .group-text {
          color: zef-color($success, default) !important;

          &.black {
            padding: 6px 16px 6px 12px !important;

            color: zef-color($ink, default) !important;
          }
        }

        .group-line {
          background: zef-color($success, default) !important;

          &.black {
            margin-right: 0 !important;
            margin-left: 12px !important;

            background: zef-color($ink, default) !important;
          }
        }
      }
    }
  }

  .color-picker {
    margin-left: -1px !important;

    hr {
      height: 2px !important;
      margin-bottom: 12px !important;
      border: none !important;

      background: zef-color($success, default) !important;
    }

    .preset-area {
      position: relative !important;

      padding: 4px 14px !important;

      .preset-label {
        position: absolute !important;
        top: -8px !important;
        right: 12px !important;

        display: inline-block !important;
        width: auto !important;
        max-width: 75% !important;
        padding: 4px 4px 4px 12px !important;

        font-size: 11px !important;
        font-weight: bold !important;
        color: zef-color($success, default) !important;
        background: #fff !important;
      }
    }
  }

  .zef-datatable-container {
    border: 1px solid zef-color($space, darker);

    .empty-row {
      padding: 24px;

      background-color: white;
    }

    .active-row {
      position: relative;

      cursor: pointer;

      &:hover::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background-color: rgba(zef-color($space, darkest), 0.3);
      }
    }

    zef-pager {
      background-color: white;
    }
  }

  .zef-datatable {
    box-sizing: border-box;
    width: 100%;
    overflow-y: hidden;

    .cdk-header-row {
      background-color: white;
    }

    .cdk-row {
      border-bottom: 1px solid zef-color($space, dark);

      &:first-child {
        border-top: 1px solid zef-color($space, dark);
      }

      &:nth-child(even) {
        background-color: rgba(zef-color($space, dark), 0.3);
      }

      &:nth-child(odd) {
        background-color: white;
      }
    }

    .cdk-cell,
    .cdk-header-cell {
      box-sizing: border-box;

      .mat-checkbox {
        .mat-checkbox-inner-container {
          width: 24px;
          height: 24px;
        }

        .mat-checkbox-frame {
          border: 2px solid zef-color($space, darkest);
          border-radius: 3px;

          background-color: zef-color($space, dark);
        }
      }

      .no-overflow {
        overflow: hidden;
        max-width: 100%;

        line-height: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .sortable-cell {
      cursor: pointer;
    }
  }

  // Custom styles to Material Grid List used in analysis
  charts-view {
    .mat-figure {
      width: 100%;
    }
  }

  // Custom styles to Material Paginator used in analysis
  text-answer-table {
    .mat-paginator-page-size {
      display: none;
    }

    .mat-cell {
      font-size: inherit;
    }
  }

  chart {
    .mat-slide-toggle {
      .mat-slide-toggle-label {
        font-size: inherit !important;
      }
    }
  }

  // Custom styles to make share link login responsive
  share-link {
    .mat-checkbox-layout {
      white-space: normal;
    }
  }

  // Custom styles for report export mat-tab
  .report-export-dialog {
    .mat-tab-group {
      border-bottom: none;

      .mat-tab-header {
        border: none !important;

        .mat-tab-labels {
          .mat-tab-label {
            opacity: 1 !important;
            width: 80px !important;
            height: 92px !important;
            padding: 0 !important;
            margin: 0 8px 0 0 !important;
            border: none !important;

            color: zef-color($ink, default) !important;
            background: transparent !important;

            &:focus {
              // background-color: zef-color($space, darker);
            }

            &.active,
            &.mat-tab-label-active {
              opacity: 1 !important;
              border: none !important;
              border-radius: 3px !important;

              background: zef-color($space, darker) !important;
              box-shadow: inset 0 1px 2px 0 rgba(17, 37, 57, 0.1) !important;
            }
          }
        }
      }

      .mat-tab-body-wrapper {
        display: none;
      }
    }
  }

  .item {
    &-info-text {
      @include item-type($info);
    }

    &-free-text {
      @include item-type($text);
    }

    &-slider {
      &-1v {
        @include item-type($vol-slider);
      }

      &-nps {
        @include item-type($nps);
      }

      &-1d {
        @include item-type($slider);
      }

      &-1r {
        @include item-type($range-slider);
      }

      &-2d {
        @include item-type($slider2d);
      }
    }

    &-choice-text {
      @include item-type($choice);

      &.item-multi {
        @include item-type($mp-choice);
      }
    }

    &-choice-single {
      @include item-type($choice);
    }

    &-choice-multi {
      @include item-type($mp-choice);
    }

    &-choice-picture {
      @include item-type($picture-choice);
    }

    &-input {
      &-string {
        @include item-type($text);
      }

      &-number {
        @include item-type($number);
      }

      &-url {
        @include item-type($url);
      }

      &-email {
        @include item-type($email);
      }

      &-phone {
        @include item-type($phone);
      }

      &-dropdown {
        @include item-type($dropdown);
      }

      &-numeric {
        @include item-type($num-dropdown);
      }

      &-checkbox {
        @include item-type($checkbox);
      }
    }

    &-file-upload {
      @include item-type($upload);
    }

    &-group {
      &-list,
      &-cards,
      &-scored {
        @include item-type($group);
      }
    }

    &-welcome {
      @include item-type($welcome);
    }

    &-goodbye,
    &-thank-you {
      @include item-type($thank-you);
    }

    &-outcome {
      @include item-type($outcome);
    }

    &-trigger {
      @include item-type($trigger);
    }
  }

  .zef-list-item-disabled {
    opacity: 0.3;
  }

  .zef-list-item-hidden {
    opacity: 0.5;
  }

  .cdk-overlay-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }

  @import './button-toggle';

  // Material Legacy Overrides
  .mat-form-field .mat-datepicker-toggle .mat-mdc-icon-button .mat-icon {
    font-size: 24px;
  }

  .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle .mat-mdc-icon-button .mat-icon {
    line-height: 24px;
  }
}

// Linda
.z-linda-container-card {
  @include linda-container-card;
}

.z-linda-container-area {
  @include linda-container-area;
}

.z-linda-highlight-alert {
  @include linda-highlight($alert);
}

.z-linda-highlight-warn {
  @include linda-highlight($warning);
}

.z-linda-highlight-success {
  @include linda-highlight($success);
}

.z-linda-highlight-contacts {
  @include linda-highlight($contacts);
}

.z-linda-highlight-primary {
  @include linda-highlight($primary);
}

.z-linda-highlight-info {
  @include linda-highlight($info);
}

.z-linda-highlight-emails {
  @include linda-highlight($emails);
}

.z-linda-highlight-sms {
  @include linda-highlight($sms);
}

@import 'src/styles/player';
