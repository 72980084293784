@use 'colors' as *;
@use 'mixins' as *;
@use 'linda' as *;

.zef-layout-card-24 {
  @include linda-container-card;
  padding: 24px;

  background-color: white;
}

.zef-layout-card-32 {
  @include linda-container-card;
  padding: 32px;
}

.zef-layout-card-header {
  display: block;
  margin-bottom: 24px;
}
