@use 'colors' as *;
@use 'linda' as *;

// scroll down for updated zef-input-xxx zef-textarea-xxx

input,
textarea {
  box-sizing: border-box;

  &::placeholder {
    font-weight: 400;
    color: zef-color($ink, lightest);
  }
}

.mat-form-field {
  &.z-input-unboxed {
    border-color: transparent;
  }

  .mat-form-field-suffix {
    display: inline-flex;
  }

  &.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-icon-button .mat-icon,
  &.mat-form-field-appearance-legacy .mat-form-field-prefix .mat-icon-button .mat-icon {
    font-size: 24px;
  }

  &.zef-text-h1 {
    font-size: 24px;
    line-height: 32px;
  }

  &.zef-text-h2 {
    font-size: 18px;
    line-height: 24px;
  }

  &.z-input-inline {
    width: auto;
    border-color: transparent;

    background-color: transparent;

    &:hover {
      border-color: zef-color($ink, lightest);
    }

    &:hover,
    &.mat-focused {
      background-color: zef-color($secondary);
    }
  }

  &.z-textarea {
    display: inline-flex;
    flex-direction: column;

    .mat-form-field-wrapper {
      .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label {
        top: 20px;
      }
    }
  }

  &.z-input-larger {
    height: 48px;

    .mat-form-field-flex .mat-form-field-infix {
      input,
      textarea {
        font-size: 18px;
        line-height: 24px;
      }

      textarea {
        + .mat-form-field-label-wrapper .mat-form-field-label {
          top: 17px;
        }
      }

      .mat-form-field-label {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }

  &.z-input-smaller,
  &.z-input-combo {
    height: 32px;
    padding: 0 !important;
    border-radius: 5px;

    &.invalid {
      border-color: zef-color($alert, default) !important;
    }

    .mat-form-field-wrapper .mat-form-field-flex {
      .mat-form-field-infix {
        padding: 4px 8px;

        input,
        textarea {
          font-size: 14px;
          line-height: 21px;
        }

        textarea {
          min-height: 38px;
          padding: 8px 4px;

          + .mat-form-field-label-wrapper .mat-form-field-label {
            top: 23px;
          }
        }
      }
    }
  }

  &.z-input-larger,
  &.z-input-smaller,
  &.z-input-combo,
  .z-input-inline,
  &.z-textarea {
    @include linda-shadow(-1);

    &.z-grey {
      background-color: zef-color($space);
    }

    &.z-input-rounded {
      border-radius: 15px;
    }

    .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix {
      padding-right: 0;
    }
  }

  &.z-input-combo {
    .mat-icon {
      @include icon-size(16);
    }

    .mat-form-field-prefix {
      padding: 0 8px !important;
    }

    .mat-form-field-infix {
      padding: 0 16px 0 0 !important;
    }
  }

  &:not(.mat-form-field-disabled) {
    &.zef-input-error,
    &.zef-textarea-error {
      border: 1px solid zef-color($alert);
    }
  }

  // anatomy
  &.zef-input-small,
  &.zef-input-search,
  &.zef-input-number,
  &.zef-input-big,
  &.zef-input-search-big,
  &.zef-input-combo,
  &.zef-textarea-small,
  &.zef-textarea-big {
    @extend %zef-input-reset;

    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 5px;

    background-color: zef-color($secondary);

    &.zef-input-space,
    &.zef-textarea-space {
      &:not(.zef-input-inline) {
        background-color: zef-color($space);
      }
    }

    &:not(.mat-form-field-disabled) {
      @include linda-shadow(-1);

      border: 1px solid zef-color($space, darkest);

      &.zef-input-ghost,
      &.zef-textarea-ghost {
        @include linda-shadow(-1);

        border-color: transparent;
      }

      &.zef-input-elevated {
        @include linda-shadow(4, true);

        border-color: transparent;
      }

      &.zef-input-inline {
        border-color: transparent;

        box-shadow: none;
      }

      &.zef-input-error,
      &.zef-textarea-error {
        border: 1px solid zef-color($alert);
      }

      &:hover {
        border: 1px solid zef-color($ink, lightest);
      }

      &.mat-focused {
        border: 1px solid zef-color($primary);
      }
    }

    &.zef-input-no-border {
      border: none !important;

      box-shadow: none !important;
    }

    &.zef-input-no-shadow {
      box-shadow: none !important;
    }

    &.zef-input-no-padding {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          padding: 0;

          .mat-form-field-infix {
            margin: 0;
          }
        }
      }
    }

    &.mat-form-field-disabled {
      color: zef-color($ink, light);

      .mat-form-field-prefix,
      .mat-form-field-suffix {
        opacity: 0.7;
      }
    }

    &.zef-hover-suffix {
      .mat-form-field-suffix {
        opacity: 0;
      }

      &:hover {
        .mat-form-field-suffix {
          opacity: 1;
        }
      }
    }

    &.zef-input-suffix-double {
      .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix {
        width: 64px;
      }
    }

    &.zef-input-search-big {
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          .mat-form-field-prefix {
            margin-left: 4px;
          }

          .mat-form-field-prefix,
          .mat-form-field-suffix {
            width: 32px;

            font-size: 32px;
            line-height: 32px;

            .mat-icon-button .mat-icon {
              font-size: 32px;
            }
          }
        }
      }
    }

    .mat-form-field-wrapper {
      width: 100%;
      height: 100%;
      padding: 0;

      .mat-form-field-flex {
        align-items: center;
        height: 100%;
        padding: 0 6px 0 8px;

        .mat-form-field-prefix,
        .mat-form-field-suffix {
          display: inline-flex;
          align-items: center;
          width: 24px;

          font-size: 24px;
          line-height: 24px;
        }

        .mat-form-field-suffix {
          width: 32px;
        }

        .mat-form-field-infix {
          display: flex;
          align-items: center;
          width: 100%;
          margin: 0 8px;

          input,
          textarea {
            top: 0;
          }

          textarea {
            height: 100%;
            padding: 0;
            margin: 0;

            resize: none;
          }

          .mat-form-field-label-wrapper {
            top: 0;

            display: flex;
            align-items: center;
            height: 100%;
            padding-top: 0;

            .mat-form-field-label {
              color: zef-color($ink, lightest);
            }
          }
        }
      }
    }

    &.zef-suffix-corner {
      .mat-form-field-wrapper .mat-form-field-flex {
        padding-right: 0;
      }
    }
  }

  &.zef-input-small,
  &.zef-input-combo,
  &.zef-input-search,
  &.zef-input-number {
    height: 32px;

    font-size: 14px;
  }

  &.zef-input-search {
    border-radius: 16px;

    &-big {
      border-radius: 24px;
    }
  }

  &.zef-input-number {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        padding: 0;

        .mat-form-field-suffix {
          width: 24px;
        }

        input {
          text-align: center;
        }
      }
    }
  }

  &.zef-input-big,
  &.zef-input-search-big {
    height: 48px;

    font-size: 18px;
    font-weight: 600;

    input {
      font-weight: 600;

      &::placeholder {
        font-weight: 600;
      }
    }
  }

  &.zef-input-inline {
    width: auto;

    background-color: transparent;

    &:hover,
    &.mat-focused {
      @include linda-shadow(-1);
    }

    &:hover {
      border-color: zef-color($ink, lightest);
    }

    &.mat-focused {
      background-color: zef-color($secondary);

      &.zef-input-space {
        background-color: zef-color($space);
      }
    }
  }

  &.zef-textarea-small,
  &.zef-textarea-big {
    .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper {
      align-items: flex-start;
    }

    .mat-form-field-prefix,
    .mat-form-field-suffix {
      position: relative;
      top: -2px;
      left: -8px;

      align-self: flex-start;
    }
  }

  &.zef-textarea-small {
    padding: 8px 0;

    font-size: 14px;
    line-height: 22px;

    .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper {
      top: 1px;
    }

    textarea {
      min-height: 44px;

      line-height: 22px;
    }

    &.zef-textarea-half {
      textarea {
        min-height: 22px;
      }
    }
  }

  &.zef-textarea-big {
    padding: 16px 0;

    font-size: 18px;
    line-height: 28px;

    .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper {
      top: 2px;
    }

    textarea {
      min-height: 56px;

      line-height: 28px;
    }

    &.zef-textarea-half {
      textarea {
        min-height: 28px;
      }
    }
  }
}

.mat-chip-list {
  flex: 1;

  .mat-chip-list-wrapper {
    min-height: 40px;
    margin: 0;
  }

  &.zef-color-muted {
    .mat-form-field-wrapper {
      background-color: zef-color($space, dark);
    }
  }

  &[class*='error'] {
    border-color: zef-color($alert, default);
  }
}

input.z-input-smaller {
  font-size: 14px;

  &::placeholder {
    color: zef-color($ink, lighter);
  }

  &.invalid {
    outline: 1px solid zef-color($alert, default) !important;
  }
}

%zef-input-reset {
  // resets for already defined styles randomly throughout the code
  overflow: initial;
  box-sizing: border-box;

  .mat-form-field-wrapper {
    display: initial;
    flex: initial;
    flex-direction: initial;

    .mat-form-field-flex {
      overflow: initial;
      flex: initial;
      flex-direction: initial;

      .mat-form-field-prefix,
      .mat-form-field-suffix {
        padding: initial;
      }

      .mat-form-field-infix {
        box-sizing: border-box;
        flex: initial;
        flex-direction: initial;
        padding: initial;
        border: initial;

        line-height: initial;

        input {
          line-height: initial;
        }

        textarea {
          flex: initial;
          height: initial;
        }

        .mat-form-field-label-wrapper {
          line-height: initial;

          .mat-form-field-label {
            top: initial;
            left: 0;

            width: 100%;

            transform: perspective(100px);
          }
        }
      }
    }

    .mat-form-field-subscript-wrapper {
      display: none;
    }
  }
}

.zef-no-mat-textarea-big-ghost {
  position: relative;

  background-color: zef-color($space, default);

  textarea {
    border: 1px solid;
    border-color: zef-color($space, default);
    border-radius: 5px;

    resize: none;

    font-size: 18px;
    line-height: 28px;

    &:focus {
      border-color: zef-color($primary, default) !important;
    }
  }

  [matSuffix] {
    position: absolute;
    top: 0;
    right: 0;

    opacity: 0;
    margin: 16px;
  }

  &:hover {
    textarea {
      border-color: zef-color($ink, lightest);
    }

    [matSuffix] {
      opacity: 1;
    }
  }

  &.show-icon {
    [matSuffix] {
      opacity: 1;
    }
  }
}

.zef-no-mat-textarea-small-ghost {
  position: relative;

  background-color: zef-color($space, default);

  input,
  textarea {
    border: 1px solid;
    border-color: zef-color($space, default);
    border-radius: 5px;

    resize: none;

    font-size: 14px;
    line-height: 22px;

    &:focus {
      border-color: zef-color($primary, default) !important;
    }
  }

  &:hover {
    textarea {
      border-color: zef-color($ink, lightest);
    }
  }
}

.zef-no-mat-textarea-combo-ghost {
  position: relative;

  background-color: zef-color($space, default);

  textarea,
  input {
    box-sizing: border-box;

    padding: 4px 16px 4px 40px;

    border: 1px solid;
    border-color: zef-color($space, default);
    border-radius: 5px;

    resize: none;

    font-size: 14px;
    line-height: 22px;

    &:focus {
      border-color: zef-color($primary, default) !important;
    }
  }

  &:hover {
    textarea,
    input {
      border-color: zef-color($ink, lightest);
    }
  }

  [matprefix] {
    position: absolute;

    top: 0;
    left: 0;

    margin: 4px 8px;
  }
}

.zef-no-mat-textarea-small {
  padding: 0;

  font-size: 14px;
  line-height: 1.6;
  background-color: zef-color($secondary);

  textarea {
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    padding: 6px 12px;

    border: 1px solid;
    border-color: zef-color($space, darkest);
    border-radius: 5px;

    resize: none;

    &:focus {
      border-color: zef-color($primary, default) !important;
    }
  }

  &:hover {
    textarea {
      border-color: zef-color($ink, lightest);
    }
  }
}

.zef-no-mat-input-small {
  padding: 0;

  font-size: 14px;
  line-height: 22px;
  background-color: zef-color($secondary);

  input {
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    padding: 4px 12px;

    border: 1px solid;
    border-color: zef-color($space, darkest);
    border-radius: 5px;

    resize: none;

    &:focus {
      border-color: zef-color($primary, default) !important;
    }
  }

  &:hover {
    input {
      border-color: zef-color($ink, lightest);
    }
  }
}

.zef-no-mat-input-big {
  padding: 0;

  font-size: 18px;
  line-height: 24px;
  background-color: zef-color($secondary);

  input {
    box-sizing: border-box;
    width: 100%;

    padding: 12px 16px;

    border: 1px solid;
    border-color: zef-color($space, darkest);
    border-radius: 5px;

    resize: none;

    &:focus {
      border-color: zef-color($primary, default) !important;
    }
  }

  &:hover {
    input {
      border-color: zef-color($ink, lightest);
    }
  }
}

.zef-no-mat-input-number {
  position: relative;

  height: 32px;

  border-radius: 5px;

  font-size: 14px;
  line-height: 22px;

  background-color: zef-color($secondary);

  input {
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    padding: 4px 32px 4px 16px;

    border: 1px solid;
    border-color: zef-color($space, darkest);
    border-radius: 5px;

    resize: none;

    &.zef-input-space {
      background-color: zef-color($space);
    }

    &:focus {
      border-color: zef-color($primary, default) !important;
    }
  }

  &:hover {
    input {
      border-color: zef-color($ink, lightest);
    }
  }

  [matsuffix] {
    position: absolute;

    top: 0;
    right: 0;
  }

  &.disabled {
    color: zef-color($ink, light);

    [matsuffix] {
      opacity: 0.7;
    }
  }
}
