@use 'colors' as *;

@mixin linda-container-card {
  overflow: hidden;
  border: 1px solid zef-color($space, darkest);
  border-radius: 10px;

  background-color: #fff;
}
@mixin linda-container-area($backgroundColor: false) {
  overflow: hidden;
  border: 1px solid zef-color($space, darkest);
  border-radius: 10px;

  @if $backgroundColor {
    background-color: $backgroundColor;
  }
  @else {
    background-color: zef-color($space);
  }
}
@mixin linda-user-avatar($size) {
  width: #{$size}px;
  height: #{$size}px;
  margin: 0;
  border: 1px solid zef-color($space, darkest);
  border-radius: 50%;

  background-size: cover;
  background-color: zef-color($space, dark);
  background-position: center;
}
@mixin linda-team-logo($size, $border: true) {
  width: #{$size}px;
  height: #{$size}px;
  margin: 0;
  border-radius: 10px;

  @if $border {
    border: 1px solid zef-color($space, darkest);
  }

  background-size: cover;
  background-color: zef-color($space, dark);
  background-position: center;
}
@mixin linda-survey-status-icon($size, $color) {
  position: relative;

  display: inline-block;
  width: #{$size}px;
  height: #{$size}px;
  margin: 0;
  border: 1px solid zef-color($space, darkest);
  border-radius: 50%;

  background-color: zef-color($secondary);

  &::after {
    content: '';
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;

    border-radius: 50%;

    background-color: $color;
  }
}
@mixin linda-hover-shadow {
  &:hover {
    cursor: pointer;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  }
}
@mixin linda-shadow($size, $soft: false) {
  @if (null != index((-1), $size)) {
    box-shadow: inset 0 $size * -1px 2px 0 rgba(zef-color($ink), if($soft, 0.1, 0.2));
  }
  @if (null != index((1 2 4 8 16), $size)) {
    box-shadow: 0 $size * 1px $size * 2px 0 rgba(zef-color($ink), if($soft, 0.1, 0.2));
  }
}
@mixin linda-folder-tile($backgroundColor, $border) {
  @include linda-hover-shadow;
  position: relative;

  box-sizing: border-box;
  min-height: 124px;
  max-width: 352px;
  padding: 24px;
  margin-top: 16px;
  border: $border;
  border-radius: 0 10px 10px;

  background-color: $backgroundColor;

  &::before {
    content: '';
    position: absolute;
    top: -16px;
    left: -1px;

    display: block;
    box-sizing: border-box;
    width: 37.5%;
    height: 16px;

    border: $border;
    border-radius: 10px 10px 0 0;

    background-color: $backgroundColor;
  }
}
@mixin linda-highlight($color) {
  position: relative;

  overflow: hidden;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 10px;

  font-size: 14px;
  color: if($color == $space, zef-color($ink, lighter), zef-color($color, if($color == $warning, darker, dark)));
  background-color: zef-color($color, if($color == $space, default, if($color == $warning, light, lighter)));

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: 4px;

    background-color: zef-color($color);
  }
}
@mixin linda-placeholder($color: $primary) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  border: 1px dashed zef-color($color);
  border-radius: 10px;
}
@mixin linda-chip($variant) {
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;

  border-radius: 16px;

  font-family: 'Open Sans';
  white-space: nowrap;
  text-align: center;

  @if ($variant == 'small') {
    height: 20px;
    padding: 4px 12px;

    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    text-transform: uppercase;
  }
  @else if($variant == 'medium') {
    height: 24px;
    padding: 4px 12px;

    font-size: 11px;
    font-weight: 600;
    line-height: 15px;
    text-transform: uppercase;
  }
  @else if($variant == 'large') {
    height: 32px;
    padding: 4px 14px;

    font-size: 14px;
    line-height: 20px;
  }
  @else if($variant == 'larger') {
    height: 32px;
    padding: 4px 14px;
    border-radius: 10px;

    font-size: 14px;
    line-height: 22px;
  }
}
@mixin icon-size($size) {
  width: #{$size}px;
  height: #{$size}px;

  font-size: #{$size}px;
  line-height: #{$size}px;
}
