@import 'src/styles/print';
@import 'src/styles/common';
@import 'src/styles/visual';
@import 'src/styles/linda';

.zef-visual {
  @import 'src/styles/language/color-classes';
  @import 'src/styles/language/shadow-classes';
  @import 'src/styles/language/text-classes';
  @import 'src/styles/language/area-classes';
  @import 'src/styles/language/card-classes';
  @import 'src/styles/language/interaction-classes';
  @import 'src/styles/language/animation-classes';
  @import 'src/styles/language/border-classes';

  @import 'src/styles/elements/buttons';
  @import 'src/styles/elements/divider';
  @import 'src/styles/elements/scrollbar';
  @import 'src/styles/elements/checkbox';
  @import 'src/styles/elements/radio';
  @import 'src/styles/elements/toggle';
  @import 'src/styles/elements/chip';
  @import 'src/styles/elements/status';

  @import 'src/styles/components/avatar';
  @import 'src/styles/components/calendar';
  @import 'src/styles/components/slider';
  @import 'src/styles/components/tabs';
  @import 'src/styles/components/tooltip';

  @import 'src/styles/layout/card';
  @import 'src/styles/layout/page';
  @import 'src/styles/layout/planks';
  @import 'src/styles/layout/sidenav';

  @import 'src/styles/templates/dropdown';
  @import 'src/styles/templates/highlight';
  @import 'src/styles/templates/grids';
  @import 'src/styles/templates/icons';
  @import 'src/styles/templates/input';
  @import 'src/styles/templates/menu';
  @import 'src/styles/templates/patterns';
  @import 'src/styles/templates/tabs';
  @import 'src/styles/templates/tiles';
}

@import 'src/styles/flex-grid';
@import 'src/styles/auth';
