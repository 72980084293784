@use 'colors' as *;
@use 'mixins' as *;
@use 'linda' as *;

.mat-button-toggle {
  font-size: 13px;
  font-weight: 600;
  color: zef-color($ink);
  background-image: zef-color($gradient, emboss);

  .mat-icon {
    color: zef-color($ink);
  }

  .mat-button-toggle-label-content {
    padding: 0 4px;

    line-height: 32px;
  }

  &-checked {
    @include box-shadow-7;

    color: zef-color($primary);
    background: zef-color($space, dark);

    .mat-icon {
      color: zef-color($primary);
    }
  }

  button {
    padding: 0 24px;
  }

  &-group {
    box-sizing: border-box;
    align-items: center;
    width: fit-content;
    height: 32px;
    border-radius: 5px;

    &.zef-toggle-transparent {
      border: none;

      .mat-button-toggle {
        border-radius: 10px;

        background-image: none;

        &:hover:not(.mat-button-toggle-checked) {
          background-color: rgba(zef-color($ink), 0.05);
        }

        + .mat-button-toggle {
          border-left: none;
        }

        .mat-button-toggle-focus-overlay {
          display: none;
        }

        &-checked {
          background: zef-color($secondary);
          box-shadow: none;
        }
      }
    }
  }
}

.zef-icon-toggle {
  .mat-button-toggle,
  &.mat-button-toggle {
    button {
      padding: 0;
    }
  }
}

.mat-button-toggle-standalone.zef-icon-toggle {
  width: 32px;
  height: 32px;
  border: 1px solid zef-color($border);
  border-radius: 5px;

  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, transparentize(zef-color($ink), 0.95) 100%);

  .mat-button-toggle-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .mat-button-toggle-label-content {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    line-height: 24px;
  }

  &.mat-button-toggle-checked {
    background-color: zef-color($space);

    @include linda-shadow(-1);
  }
}
