@use 'colors' as *;

@mixin area($color, $variant: light, $border: 0, $border-variant: default) {
  .zef-area-#{$color} {
    border: $border;
    border-color: zef-color(map-get($zef-palettes, $color), $border-variant);
    border-radius: 10px;

    color: zef-contrast(map-get($zef-palettes, $color), $variant);
    background-color: zef-color(map-get($zef-palettes, $color), $variant);
  }
}

@include area(primary);
@include area(success);
@include area(warning);
@include area(alert);
@include area(accent, lighter, 1px solid);
