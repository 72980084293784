@use 'colors' as *;
@use 'mixins' as *;
@use 'linda' as *;

@import '../../../src/styles/main';

@import url('./assets/flags.css');

@import url('./assets/icons/style-free.css');
@import url('./assets/icons/style-full.css');
