@use 'colors' as *;

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }

  @keyframes #{$animation-name} {
    @content;
  }
}
@mixin animation($name, $delay, $duration, $direction: linear, $iteration: 1, $easing: linear) {
  -webkit-animation-name: $name;
  animation-name: $name;

  -webkit-animation-delay: $delay;
  animation-delay: $delay;

  -webkit-animation-duration: $duration;
  animation-duration: $duration;

  -webkit-animation-direction: $direction;
  animation-direction: $direction;

  -webkit-animation-iteration-count: $iteration;
  animation-iteration-count: $iteration;

  -webkit-animation-timing-function: $easing;
  animation-timing-function: $easing;
}

@include keyframes(zef-animation-fade) {
  0% {
    background-color: zef-color($primary, default);
  }

  50% {
    background-color: transparentize(zef-color($primary, default), 0.25);
  }

  70% {
    background-color: transparentize(zef-color($primary, default), 0.45);
  }

  90% {
    background-color: transparentize(zef-color($primary, default), 0.75);
  }

  100% {
    background-color: transparentize(zef-color($primary, default), 0.75);
  }
}
@include keyframes(zef-animation-spinning) {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@mixin box-shadow-1 {
  box-shadow: 0 0 2px transparentize(zef-color($ink, default), 0.8);
}
@mixin box-shadow-2 {
  box-shadow: 0 1px 4px transparentize(zef-color($ink, default), 0.8);
}
@mixin box-shadow-3 {
  box-shadow: 0 1px 8px transparentize(zef-color($ink, default), 0.8);
}
@mixin box-shadow-4 {
  box-shadow: 0 2px 12px transparentize(zef-color($ink, default), 0.8);
}
@mixin box-shadow-5 {
  box-shadow: 0 2px 16px transparentize(zef-color($ink, default), 0.8);
}
@mixin box-shadow-6 {
  box-shadow: 0 8px 16px transparentize(zef-color($ink, default), 0.8);
}
@mixin box-shadow-7 {
  box-shadow: inset 0 1px 2px -1px transparentize(zef-color($ink, default), 0.8);
}
@mixin box-shadow-8 {
  box-shadow: 0 4px 8px 0 transparentize(zef-color($ink, default), 0.8);
}
@mixin zef-button-interact {
  position: relative;

  outline: none;

  &:active::after,
  &:hover::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    cursor: pointer;
  }

  &:hover::after {
    background-color: rgba(white, 0.2);
  }

  &:active::after {
    background-color: rgba(black, 0.2);
  }
}
