@use 'colors' as *;

$items: emails, sms, responses, contacts, promo, space, embed, popup, vibrant, link, ink, stealth, accent, primary,
  secondary, alert, warning, success, logic, scoring, trigger, smart, discover, owner, admin, member, border,
  chart-highlight;

$transparencies: 10 20 30 50;
$additionalTransparencies: join($transparencies, 5 15 95);

@each $item in $items {
  $palette: map-get($zef-palettes, $item);
  $hue: if($item == warning, dark, default);

  .zef-bgcolor-#{$item} {
    color: zef-contrast($palette, $hue);
    background-color: zef-color($palette, $hue);
  }

  .zef-hyper-link.zef-color-#{$item},
  .zef-color-#{$item} {
    color: zef-color($palette, $hue);
  }

  $currentTransparencies: if($item == ink or $item == secondary, $additionalTransparencies, $transparencies);

  @each $transparency in $currentTransparencies {
    .zef-bgcolor-#{$item}-#{$transparency} {
      color: zef-contrast($palette);
      background-color: transparentize(zef-color($palette), (100 - $transparency) * 0.01);
    }

    .zef-color-#{$item}-#{$transparency} {
      color:
        transparentize(
          zef-color($palette, $hue),
          (100 - $transparency) * 0.01
        );
    }
  }
}

.zef-color-primary-inverted {
  color: zef-color($primary, inverted);
}

.zef-color-warning-dark {
  color: zef-color($warning, dark);
}

.zef-bgcolor-space {
  color: zef-contrast($space);
  background-color: zef-color($space);
}

.zef-bgcolor-space-darker {
  color: zef-contrast($space, darker);
  background-color: zef-color($space, darker);
}

.zef-bgcolor-space-darkest {
  color: zef-contrast($space, darkest);
  background-color: zef-color($space, darkest);
}

.zef-bgcolor-space-dark {
  color: zef-contrast($space, dark);
  background-color: zef-color($space, dark);
}

.zef-bgcolor-primary-light {
  color: zef-contrast($primary, light);
  background-color: zef-color($primary, light);
}

.zef-bgcolor-ink-lightest {
  color: zef-contrast($ink, lightest);
  background-color: zef-color($ink, lightest);
}

.zef-color-red {
  color: zef-color($alert);
}

.zef-text-light {
  color: zef-color($ink, light);
}

.zef-text-lighter,
.zef-color-off {
  color: zef-color($ink, lighter);
}

.zef-text-lighter a {
  color: zef-color($ink, lighter);
}

.zef-text-lightest,
.zef-color-ink-lightest,
.zef-color-muted {
  color: zef-color($ink, lightest);
}

.zef-color-warn {
  color: zef-color($warning, dark);
}

.zef-bgcolor-logic-dark {
  background-color: zef-color($logic, dark);
}

.zef-color-space-darkest {
  color: zef-color($space, darkest);
}

.zef-color-hubspot {
  color: zef-color($zef-color-social, hubspot);
}

.zef-color-youtube {
  color: zef-color($zef-color-social, youtube);
}
