/*
  ZEF Linda colors and material palettes.

  Usage examples:

    zef-color($primary, light)
    zef-color($primary, light-contrast)

  Do not use the color maps directly!
*/

@use 'material' as mat;

@use 'sass:math';
@use 'sass:map';

@function zef-color($color, $variant: default, $variantVariant: default) {
  @if ($variant == 'darkish') {
    $m: mat.get-color-from-palette($ink, default);
    $c: mat.get-color-from-palette($color, $variantVariant);

    @return mix($m, $c, 10%);
  }
  @if ($variant == 'darken') {
    $m: mat.get-color-from-palette($ink, default);
    $c: mat.get-color-from-palette($color, $variantVariant);

    @return mix($m, $c, 20%);
  }
  @else if($variant == 'lighten') {
    $m: mat.get-color-from-palette($space, default);
    $c: mat.get-color-from-palette($color, $variantVariant);

    @return mix($m, $c, 20%);
  }
  @else {
    @return mat.get-color-from-palette($color, $variant);
  }
}
@function zef-contrast($color, $variant: default) {
  @return mat.get-contrast-color-from-palette($color, $variant);
}
@function define-palette($base-palette, $default: 500, $lighter: 100, $darker: 700, $text: $default) {
  @each $hue in $default $lighter $darker $text {
    @if not map.has-key($base-palette, $hue) {
      $base-palette: map.merge($base-palette, ($hue: map.get($base-palette, $default)));
      $base-palette: map.merge(
        $base-palette,
        (contrast: map.merge(
          map.get($base-palette, contrast),
          ($hue: map.get(map.get($base-palette, contrast), $default))
        ))
      );
    }
  }

  @return mat.define-palette($base-palette, $default, $lighter, $darker, $text);
}

/* stylelint-disable function-max-empty-lines */

$zef-color-white: (
  default: white,
  contrast: (
    default: #112539,
  ),
);

$zef-color-ink: (
  lightest: #93a0ab,
  lighter: #60717f,
  light: #495c6c,
  default: #112539,
  contrast: (
    lightest: #fff,
    lighter: #fff,
    light: #fff,
    default: #fff,
  ),
);

$zef-color-space: (
  default: #f9fafb,
  dark: #f3f6f7,
  darker: #eaeff1,
  darkest: #dae2e5,
  contrast: (
    default: #112539,
    dark: #112539,
    darker: #112539,
    darkest: #112539,
  ),
);

$zef-color-stealth: (
  default: #495c6c,
  contrast: (
    default: #fff,
  ),
);

$zef-color-chart-highlight: (
  lighter: #fdfaf7,
  light: #faf4ee,
  default: #cc9256,
  dark: #8f663c,
  darker: #3d2c1a,
  contrast: (
    default: #fff,
  ),
  inverted: #e5c8aa,
);

$zef-color-vibrant: (
  500: #ef5d7d,
  lighter: #fef5f6,
  light: #fdeced,
  default: #ef5d65,
  dark: #bd4044,
  darker: #4d0003,
  contrast: (
    500: #fff,
    lighter: #112539,
    light: #112539,
    default: #fff,
    dark: #fff,
    darker: #fff,
  ),
);

$zef-color-accent: (
  lighter: #fcf8f5,
  light: #f9f2eb,
  default: #cc9256,
  dark: #995726,
  darker: #472812,
  contrast: (
    lighter: #112539,
    light: #112539,
    default: #fff,
    dark: #fff,
    darker: #fff,
  ),
);

$zef-color-primary: (
  500: #0083ff,
  lighter: #ecf5ff,
  light: #d9ecff,
  default: #0083ff,
  dark: #0046d1,
  darker: #026,
  contrast: (
    500: #0083ff,
    lighter: #112539,
    light: #112539,
    default: #fff,
    dark: #fff,
    darker: #fff,
  ),
  inverted: #80c1ff

);

$zef-color-secondary: (
  default: #fff,
  contrast: (
    default: #112539,
  ),
);

$zef-color-alert: (
  lighter: #fdeff4,
  light: #fce0e9,
  default: #e20046,
  dark: #a30018,
  darker: #40000a,
  contrast: (
    lighter: #112539,
    light: #112539,
    default: #fff,
    dark: #fff,
    darker: #fff,
  ),
);

$zef-color-warning: (
  500: #fee119,
  lighter: #fffdf1,
  light: #fffbe3,
  default: #fee119,
  dark: #b39005,
  darker: #4d3e02,
  contrast: (
    500: #fee119,
    lighter: #112539,
    light: #112539,
    default: #112539,
    dark: #fff,
    darker: #fff,
  ),
);

$zef-color-success: (
  lighter: #f1faf3,
  light: #e3f6e7,
  default: #18b53a,
  dark: #077814,
  darker: #033309,
  contrast: (
    500: #0e4c95,
    lighter: #112539,
    light: #112539,
    default: #fff,
    dark: #fff,
    darker: #fff,
  ),
);

$zef-color-emails: (
  lighter: #faf0f7,
  light: #e6b7d8,
  default: #cf1196,
  dark: #910c69,
  darker: #3d052c,
  contrast: (
    500: #0e4c95,
    lighter: #112539,
    light: #112539,
    default: #fff,
    dark: #fff,
    darker: #fff,
  ),
);

$zef-color-sms: (
  lighter: #f0f7e5,
  default: #5ca600,
  dark: #345f02,
  contrast: (
    lighter: #112539,
    default: #fff,
    dark: #fff,
  ),
);

$zef-color-link: (
  default: #1143cf,
  contrast: (
    default: #fff,
  ),
);

$zef-color-embed: (
  default: #118fcf,
  contrast: (
    default: #fff,
  ),
);

$zef-color-popup: (
  default: #00afeb,
  contrast: (
    default: #fff,
  ),
);

$zef-color-responses: (
  lighter: #f7f1fd,
  light: #d6b9f7,
  default: #7715e3,
  dark: #490491,
  darker: #29074d,
  contrast: (
    lighter: #112539,
    light: #112539,
    default: #fff,
    dark: #fff,
    darker: #fff,
  ),
);

$zef-color-contacts: (
  lighter: #e5f9fa,
  light: #ccf1f5,
  default: #0bc,
  dark: #00818c,
  darker: #00464d,
  contrast: (
    500: #0e4c95,
    lighter: #112539,
    light: #112539,
    default: #fff,
    dark: #fff,
    darker: #fff,
  ),
);

$zef-color-social: (
  email: #f6c563,
  facebook: #324d8f,
  twitter: #00aced,
  linkedin: #0077b5,
  googleplus: #d34836,
  whatsapp: #25d366,
  hubspot: #fa7820,
  salesforce: #1798c1,
  youtube: #f00,
  contrast: (
    email: #fff,
    facebook: #fff,
    twitter: #fff,
    linkedin: #fff,
    googleplus: #fff,
    whatsapp: #fff,
    hubspot: #fff,
    youtube: #fff,
  ),
);

$zef-color-gradient: (
  emboss: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05)),
  dark: linear-gradient(to bottom, rgba(white, 0), rgba(0, 0, 0, 0.1)),
  darker: linear-gradient(to bottom, rgba(white, 0), rgba(0, 0, 0, 0.15)),
  default: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)),
  contrast: (
    default: #112539,
  ),
);

$promo: define-palette(
  (
    default: #0da9ab,
    contrast: (
      default: #fff,
    )
  ),
  default
);

$email-share: define-palette(
  (
    default: #e6a82e,
    contrast: (
      default: #fff,
    )
  ),
  default
);

$facebook: define-palette(
  (
    default: #324d8f,
    contrast: (
      default: #fff,
    )
  ),
  default
);

$twitter: define-palette(
  (
    default: #00aced,
    contrast: (
      default: #fff,
    )
  ),
  default
);

$linkedin: define-palette(
  (
    default: #0077b5,
    contrast: (
      default: #fff,
    )
  ),
  default
);

$whatsapp: define-palette(
  (
    default: #25d366,
    contrast: (
      default: #fff,
    )
  ),
  default
);

$youtube: define-palette(
  (
    default: #f00,
    contrast: (
      default: #fff,
    )
  ),
  default
);

$hubspot: define-palette(
  (
    default: #fa7820,
    contrast: (
      default: #fff,
    )
  ),
  default
);

$salesforce: define-palette(
  (
    default: #1798c1,
    contrast: (
      default: #fff,
    )
  ),
  default
);

// Generate material palettes

$white: define-palette($zef-color-white, default);
$ink: define-palette($zef-color-ink, default, lighter, darker);
$space: define-palette($zef-color-space, default, lighter, darker);
$stealth: define-palette($zef-color-stealth, default, lighter, darker);

$vibrant: define-palette($zef-color-vibrant, default, lighter, darker);
$accent: define-palette($zef-color-accent, default, lighter, darker);
$primary: define-palette($zef-color-primary, default, lighter, darker);
$secondary: define-palette($zef-color-secondary, default, lighter, darker);

$alert: define-palette($zef-color-alert, default, lighter, darker);
$warning: define-palette($zef-color-warning, default, lighter, darker);
$success: define-palette($zef-color-success, default, lighter, darker);

$sms: define-palette($zef-color-sms, default, lighter, darker);
$link: define-palette($zef-color-link, default);
$embed: define-palette($zef-color-embed, default);
$popup: define-palette($zef-color-popup, default);
$emails: define-palette($zef-color-emails, default, lighter, darker);
$responses: define-palette($zef-color-responses, default, lighter, darker);
$contacts: define-palette($zef-color-contacts, default, lighter, darker);

$social: define-palette($zef-color-social, default, lighter, darker);
$gradient: define-palette($zef-color-gradient, default, lighter, darker);

// chart palettes

$chart-ink: define-palette(
  (
    default: #495c6c,
    contrast: (
      default: #fff,
    )
  ),
  default
);

$chart-space: define-palette(
  (
    default: #dae2e5,
    contrast: (
      default: #112539,
    )
  ),
  default
);

$chart-now: define-palette(
  (
    default: #a0f,
    contrast: (
      default: #fff,
    )
  ),
  default
);

$chart-highlight: define-palette($zef-color-chart-highlight, default, lighter, darker);

// generate editor palettes

@function color-contrast($color) {
  $yiq: math.div(red($color) * 299 + green($color) * 587 + blue($color) * 114, 1000);

  @return if($yiq >= 140, zef-color($ink), zef-color($secondary));
}
@function editor-palette($color) {
  @return define-palette(
    (
      lighter: mix($color, #fff, 10%),
      light: mix($color, #fff, 33%),
      default: $color,
      contrast: (
        default: color-contrast($color),
        light: color-contrast(mix($color, #fff, 33%)),
        lighter: color-contrast(mix($color, #fff, 10%)),
      ),
    ),
    default,
    lighter
  );
}

$slider2d: editor-palette(#4c9a57);
$slider: editor-palette(#4c9964);
$nps: editor-palette(#5e9945);
$text: editor-palette(#c6ab43);
$picture-choice: editor-palette(#bd66cc);
$choice: editor-palette(#c763a7);
$mp-choice: editor-palette(#c861b8);
$checkbox: editor-palette(#c76496);
$dropdown: editor-palette(#c66685);
$num-dropdown: editor-palette(#c66775);
$vol-slider: editor-palette(#4d9971);
$range-slider: editor-palette(#4e987f);
$email: editor-palette(#0eabad);
$number: editor-palette(#c69f5d);
$phone: editor-palette(#0eabad);
$url: editor-palette(#cc8b5c);
$upload: editor-palette(#cc785c);
$group: editor-palette(#469);
$info: editor-palette(#5e30ca);
$welcome: editor-palette(#7647cc);
$thank-you: editor-palette(#8fb549);
$outcome: editor-palette(#9eb549);

$logic: define-palette(
  (
    lighter: #f5e6ff,
    light: #cea0eb,
    default: #9a2cde,
    dark: #52107a,
    contrast: (
      lighter: color-contrast(#f5e6ff),
      light: color-contrast(#cea0eb),
      default: color-contrast(#9a2cde),
      dark: color-contrast(#52107a),
    ),
  ),
  default,
  lighter,
  darker
);

$scoring: define-palette(
  (
    lighter: #dcf5eb,
    light: #9de0c4,
    default: #00ad65,
    contrast: (
      lighter: color-contrast(#dcf5eb),
      light: color-contrast(#9de0c4),
      default: color-contrast(#00ad65),
    ),
  ),
  default,
  lighter,
  darker
);

$trigger: define-palette(
  (
    lighter: #e6f6ff,
    light: #a0d1eb,
    default: #0e6ea1,
    contrast: (
      lighter: color-contrast(#e6f6ff),
      light: color-contrast(#a0d1eb),
      default: color-contrast(#0e6ea1),
    ),
  ),
  default,
  lighter,
  darker
);

// Derived colors

$border: define-palette(
  (
    default: zef-color($space, darkest),
    contrast: (
      default: zef-contrast($space, darkest)
    )
  ),
  default
);

$smart: define-palette(
  (
    default: zef-color($vibrant),
    contrast: (
      default: zef-contrast($vibrant)
    )
  ),
  default
);

$discover: define-palette(
  (
    default: zef-color($accent),
    contrast: (
      default: zef-contrast($accent)
    )
  ),
  default
);

$owner: define-palette(
  (
    default: zef-color($success, dark),
    contrast: (
      default: zef-contrast($success, dark)
    )
  ),
  default
);

$admin: define-palette(
  (
    default: zef-color($warning, dark),
    contrast: (
      default: zef-contrast($warning, dark)
    )
  ),
  default
);

$member: define-palette(
  (
    default: zef-color($primary, dark),
    contrast: (
      default: zef-contrast($primary, dark)
    )
  ),
  default
);

$ink-lighter: define-palette(
  (
    default: zef-color($ink, lighter),
    contrast: (
      default: zef-contrast($ink, lighter)
    )
  ),
  default
);

$zef-palettes: (
  white: $white,
  ink: $ink,
  space: $space,
  stealth: $stealth,
  vibrant: $vibrant,
  accent: $accent,
  primary: $primary,
  secondary: $secondary,
  alert: $alert,
  warning: $warning,
  success: $success,
  sms: $sms,
  link: $link,
  embed: $embed,
  popup: $popup,
  emails: $emails,
  responses: $responses,
  contacts: $contacts,
  social: $social,
  gradient: $gradient,
  promo: $promo,
  // Editor
  slider2d: $slider2d,
  slider: $slider,
  nps: $nps,
  text: $text,
  picture-choice: $picture-choice,
  choice: $choice,
  mp-choice: $mp-choice,
  checkbox: $checkbox,
  dropdown: $dropdown,
  num-dropdown: $num-dropdown,
  vol-slider: $vol-slider,
  range-slider: $range-slider,
  email: $email,
  number: $number,
  phone: $phone,
  url: $url,
  file-upload: $upload,
  group: $group,
  info: $info,
  welcome: $welcome,
  thank-you: $thank-you,
  outcome: $outcome,
  trigger: $trigger,
  logic: $logic,
  scoring: $scoring,
  // 3rd party
  email-share: $email-share,
  facebook: $facebook,
  twitter: $twitter,
  linkedin: $linkedin,
  whatsapp: $whatsapp,
  youtube: $youtube,
  hubspot: $hubspot,
  salesforce: $salesforce,
  // charts
  c-ink: $chart-ink,
  c-space: $chart-space,
  c-now: $chart-now,

  border: $border,
  smart: $smart,
  discover: $discover,
  owner: $owner,
  admin: $admin,
  member: $member,

  chart-highlight: $chart-highlight,
  ink-lighter: $ink-lighter,
);

@function getPalettes($palettes, $source: $zef-palettes) {
  $filtered: ();

  @each $palette in $palettes {
    $filtered: map-merge($filtered, (#{$palette}: map-get($source, $palette)));
  }

  @return $filtered;
}
